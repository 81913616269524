import React from 'react'
import NextLink from 'next/link'

import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Typography,
    SwipeableDrawer,
    Button,
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import HomeIcon from '@mui/icons-material/Home'
import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'

type LayoutProps = React.PropsWithChildren<{}>
const drawerWidth = 220

const navigationOptions = [
    {
        name: 'Dashboard',
        icon: <HomeIcon style={{ color: '#FFFFFF' }} />,
        href: '/dashboard',
        pathnameRoot: '/dashboard',
    },
]

const StyledFooter = styled('footer')({
    display: 'flex',
    flex: 1,
    padding: ' 2rem 0',
    borderTop: '1px solid #eaeaea',
    justifyContent: 'center',
    alignItems: 'center',
})

const MainLayoutStylingWithBreakpoints = {
    marginTop: {
        xs: 2,
        sm: 5,
    },
    marginRight: {
        xs: 2,
    },
    marginLeft: {
        xs: 2,
        md: 5,
        xl: '96px',
    },
    marginBottom: {
        xs: 2,
        lg: 4,
    },
    paddingBottom: {
        xs: '50px !important',
        lg: 0,
    },
    padding: {
        lg: 0,
    },
    flexGrow: 1,
    bgcolor: 'background.default',
}

function getFirstPathSeg(pathname: string) {
    try {
        return `/${pathname.split('/')[1]}`
    } catch (error) {
        return `/`
    }
}

const LayoutContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const DrawerContent = styled(Box)(({ theme }) => ({
    background: '#253A8E',
    boxShadow: '0px 2px 5px 0px #0000000F',
    height: '100%',
    color: 'white',
}))

const TitleText = styled(Typography)(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
}))

const navOptFilter = () => true

export const OnboardingPortalLayout = ({ children, ...props }: LayoutProps) => {
    const { logout } = useAuth0()
    const router = useRouter()
    const [open, setOpen] = React.useState(false)

    return (
        <LayoutContainer>
            <Box mt={5}>
                <Button
                    onClick={() => setOpen(!open)}
                    style={{
                        color: 'black',
                        alignItems: 'left',
                        width: 'fit-content',
                    }}
                >
                    <MenuIcon fontSize="large" />
                </Button>
            </Box>
            <SwipeableDrawer
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                sx={{ height: '100%' }}
            >
                <DrawerContent>
                    <Box style={{ width: '100%' }} mt={5} mb={2}>
                        <TitleText variant="h6" fontWeight={550}>
                            Belfry
                        </TitleText>
                    </Box>
                    <List>
                        {navigationOptions
                            .filter(navOptFilter)
                            .map((navigationOption) => (
                                <NextLink
                                    href={navigationOption.href}
                                    key={navigationOption.name}
                                    passHref
                                    legacyBehavior
                                >
                                    <ListItem
                                        key={navigationOption.name}
                                        component="a"
                                        onClick={() => setOpen(false)}
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor:
                                                    'rgba(129, 136, 157, 0.24)',
                                            },
                                        }}
                                        selected={
                                            getFirstPathSeg(router.pathname) ==
                                            getFirstPathSeg(
                                                navigationOption.href
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            {navigationOption.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                style: {
                                                    fontFamily: 'Montserrat',
                                                    fontWeight: 500,
                                                },
                                            }}
                                            primary={navigationOption.name}
                                        />
                                    </ListItem>
                                </NextLink>
                            ))}
                    </List>

                    <List style={{ marginTop: `auto` }}>
                        <ListItem>
                            <StyledFooter>
                                <ListItem
                                    key={'Logout'}
                                    component="a"
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor:
                                                'rgba(129, 136, 157, 0.24)',
                                        },
                                    }}
                                    onClick={() =>
                                        logout({
                                            returnTo: window.location.origin,
                                        })
                                    }
                                >
                                    <ListItemIcon>
                                        <LogoutIcon
                                            style={{ color: '#FFFFFF' }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            style: {
                                                fontFamily: 'Montserrat',
                                                fontWeight: 500,
                                            },
                                        }}
                                        primary={'Logout'}
                                    />
                                </ListItem>
                            </StyledFooter>
                        </ListItem>
                    </List>
                </DrawerContent>
            </SwipeableDrawer>

            <Box component="main" sx={MainLayoutStylingWithBreakpoints}>
                {children}
            </Box>
        </LayoutContainer>
    )
}
